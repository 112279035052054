<template>
  <div id="vue-app-two">
    <!-- <div class="container servicesSearch">
      <div class="row">
                <div class="col-12 ">
          <input type="search" v-model="searchValue" placeholder="Search service name or category" autocomplete="off" name="keyword" id="keyword" minlength="3">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
        <div id="datafetch" class="results hide" v-if="validName">
          <div class="row">
            <div class="col-12 text-center" v-for="title in filterByTerm" :key="title.id">
              <span v-if="title.type === 'Service Name'"> <a :href="`https://waysprd.wpengine.com/service/` + title.slug ">
                  <span> {{ title.name }}</span>
                </a></span>
             	 <span v-else><a :href="`https://waysprd.wpengine.com/services/` + title.slug ">
                  <span> {{ title.name }} - <small class="">{{title.type}}</small></span>
                </a></span>
            </div>
          </div>
          <div class="support"></div>
        </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import slugify from "slugify";

import json from "@/stores/ServiceStore.json";
export default {
  name: "Search",
  components: {},
  data() {
    return {
      servicesJson: "",
      search: "",
      services: [],
      titles: [],
      searchValue: "",
      categories: [],
      orgs: [],
      join: [],
      arrFiltered: [],
      slug: "",
      catUniq: [],
      bigArray: [],
      simpleArr: [],
      validName: false,
      componentLoaded: false,
      place: null,
    };
  },
  beforeMount() {},
  computed: {
    filterByTerm() {
      return this.join.filter((title) => {
        return title.name.toLowerCase().match(this.searchValue.toLowerCase());
      });
    },
  },
  methods: {
    setPlace(place) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.place = place;
      console.log(lng);
      console.log(lat);
    },

    searchServices() {
      this.servicesJson.forEach((service) => {
        let arrFiltered = [];
        let services = [];
        let titles = [];
        let categories = [];
        this.services.push(service);
        this.categories.push(service.categories);
        this.titles.push({
          name: service.title,
          type: "Service Name",
          slug: service.slug,
        });
        this.orgs.push({
          name: service.org,
          type: "Organisation",
          slug: service.slugOrg,
        });
        this.bigArray = this.categories.flat();
      });

      const catUniq = [...new Set(this.bigArray)];
      // console.log(catUniq)
      let simpleArr = [];
      catUniq.forEach((category) => {
        let slugCat = slugify(category, {
          replacement: "-",
          remove: /[$*_,+~.()'"!\-:@]/g,
          lower: true,
        });

        this.simpleArr.push({
          name: category,
          type: "Category Search",
          slug: slugCat,
        });
      });
      //  console.log(this.simpleArr)
      this.orgs = [...new Set(this.orgs)];
      var result = this.orgs.reduce((unique, o) => {
        if (!unique.some((obj) => obj.name === o.name && obj.type === o.type)) {
          unique.push(o);
        }
        return unique;
      }, []);

      this.arrFiltered = result;
      this.join = this.arrFiltered.concat(this.titles, this.simpleArr);
      // console.log(this.join);
    },
  },

  watch: {
    searchValue: function(newValue) {
      if (newValue.length > 3) {
        this.validName = true;
      } else if (newValue.length < 3) {
        this.validName = false;
      }
    },
  },

  mounted() {
    // console.log(this.componentLoaded)
    if (this.componentLoaded === false) {
      // console.log('mounted');
    }
  },

  created() {
    // console.log('created');
    const axios = require("axios");

    (this.componentLoaded = true),
      // axios.get("http://localhost:5000/api/service")
      axios
        .get("service/?token=005182f96b3e68d0acc30fbccfc633d5")

        .then((response) => {
          // console.log(response);
          this.servicesJson = response.data;
          //console.log('step1');
        })
        .then((response) => {
          this.searchServices();
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        })
        .finally(() => {
          this.componentLoaded = false;
          //console.log(this.componentLoaded)
          // console.log(this.servicesJson)
        });
  },
};
</script>

<style></style>
