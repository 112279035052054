<template>
  <div class="home">
    <div class="container doctorSearch">
      <div class="row">
        <div class="col-12">
          <div class="row w-100">
            <div class="col-lg-6 col-12">
              <gmap-autocomplete
                :options="autocompleteOptions"
                @place_changed="setPlace"
              ></gmap-autocomplete>
            </div>
            <div class="col-lg-6 col-12">
              <select v-model="radius">
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>

          <div clas="row">
            <div class="col-12">
              <a
                :href="url(item)"
                id="submitApartmentSearch"
                class="btn btn-dark text-white w-100"
                type="button"
                >Search Listing</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row col-md-12">
            <div class="col-md-6">
              <a
                :href="
                  `https://wayouthsd.linux.hostaway.net.au/services/youth-friendly-doctors/`
                "
              >
                <div
                  class="icon-block d-flex flex-column align-items-center justify-content-center px-md-5 px-3"
                >
                  <div class="swap mb-3">
                    <img
                      class="w-100"
                      src="https://wayouthsd.linux.hostaway.net.au/wp-content/uploads/2021/07/doctor.png"
                    />
                  </div>
                  <div
                    class="icon-title font-weight-bold text-black text-center w-100"
                  >
                    <strong> Youth Friendly Doctors </strong>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-6">
              <a
                href="javascript:void(0)"
                name="waydoctorssearch"
                v-model="waydoctorssearch"
                value="Youth Service Directory"
                v-scroll-to="{
                  el: '#SearchServices',
                  offset: 50,
                  duration: 3000,
                  easing: 'linear',
                }"
              >
                <div
                  class="icon-block d-flex flex-column align-items-center justify-content-center px-md-5 px-3"
                >
                  <div class="swap mb-3">
                    <img
                      class="w-100"
                      src="https://wayouthsd.linux.hostaway.net.au/wp-content/uploads/2021/07/3211556.png"
                    />
                  </div>
                  <div
                    class="icon-title font-weight-bold text-black text-center w-100"
                  >
                    <strong> Youth Service Directory </strong>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
import json from "@/stores/ServiceStore.json";
import Spinner from "@/components/Spinner.vue";
import * as VueGoogleMaps from "vue2-google-maps";

export default {
  name: "Home",
  components: {
    Spinner,
  },
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  props: [],
  data() {
    return {
      autocompleteOptions: {
        componentRestrictions: {
          country: ["au"],
        },
      },
      waydoctorssearch: [], //new line added by keshav
      servicesJson: "",
      search: "",
      services: [],
      titles: [],
      searchValue: "",
      categories: [],
      orgs: [],
      join: [],
      arrFiltered: [],
      slug: "",
      catUniq: [],
      bigArray: [],
      simpleArr: [],
      validName: false,
      componentLoaded: false,
      place: null,
      lat: null,
      radius: null,
      item: "",
      name: "John",
      defaultsearch: false,
    };
  },
  beforeMount() {},
  computed: {
    filterByTerm() {
      return this.join.filter((title) => {
        return title.name.toLowerCase().match(this.searchValue.toLowerCase());
      });
    },
  },
  methods: {
    setPlace(place) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.place = place;
      this.lat = lat;
      this.lng = lng;
      console.log(lng);
      console.log(lat);
    },

    url: function(item) {
      let listingURL = window.location.origin + "/youth-doctors/";
      let urlQueries = [];
      let counter = 0;
      if (this.lat !== null) {
        urlQueries.push("lat=" + this.lat);
      }
      if (this.lng !== null) {
        urlQueries.push("lng=" + this.lng);
      }
      if (this.radius !== null) {
        urlQueries.push("r=" + this.radius);
      }
      if (urlQueries.length > 0) {
        urlQueries.forEach(function(item) {
          if (counter === 0) {
            listingURL += "?";
            listingURL += item;
          }
          if (counter > 0) {
            listingURL += "&";
            listingURL += item;
          }
          counter++;
        });
      }
      return listingURL;
    },

    searchServices() {
      this.servicesJson.forEach((service) => {
        let arrFiltered = [];
        let services = [];
        let titles = [];
        let categories = [];
        this.services.push(service);
        this.categories.push(service.categories);
        // console.log("first " + this.categories);
        this.titles.push({
          name: service.title,
          type: "Service Name",
          slug: service.slug,
        });
        this.orgs.push({
          name: service.org,
          type: "Organisation",
          slug: service.slugOrg,
        });
        this.bigArray = this.categories.flat();
      });

      const catUniq = [...new Set(this.bigArray)];
      // console.log(catUniq)
      let simpleArr = [];
      catUniq.forEach((category) => {
        let slugCat = slugify(category, {
          replacement: "-",
          remove: /[$*_,+~.()'"!\-:@]/g,
          lower: true,
        });
        // console.log(slugCat);
        this.simpleArr.push({
          name: category,
          type: "Category Search",
          slug: slugCat,
        });
      });
      //  console.log(this.simpleArr)
      this.orgs = [...new Set(this.orgs)];
      var result = this.orgs.reduce((unique, o) => {
        if (!unique.some((obj) => obj.name === o.name && obj.type === o.type)) {
          unique.push(o);
        }
        return unique;
      }, []);

      this.arrFiltered = result;
      this.join = this.arrFiltered.concat(this.titles, this.simpleArr);
      //console.log(this.join);
    },
  },

  watch: {
    searchValue: function(newValue) {
      if (newValue.length > 3) {
        this.validName = true;
      } else if (newValue.length < 3) {
        this.validName = false;
      }
    },
  },

  mounted: function() {
    console.log(VueGoogleMaps.loadGmapApi);
  },

  created() {
    console.log("created");
    const axios = require("axios");
    (this.componentLoaded = true),
      // axios.get("http://localhost:5000/api/service")
      axios
        .get("service/?token=005182f96b3e68d0acc30fbccfc633d5")

        .then((response) => {
          this.servicesJson = response.data;
          // console.log("step1");
        })
        .then((response) => {
          // console.log("first called");
          this.searchServices();
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        })
        .finally(() => {
          this.componentLoaded = false;
          console.log(this.componentLoaded);
          // console.log(this.servicesJson);
        });
  },
};
</script>
<style>
.feed-headline {
  font-size: 12px;
  line-height: 22px;
  border-bottom: 1px solid #00a8a5;
}

.live-feed-tracker {
  background: #fff;
}
</style>
