<template>
  <div class="container-fluid">
    <div class="grey-overlay"></div>
    <div class="row search-section gutters">
      <div class="col-md-4">
        <!--<multiselect
          v-model="form.valuelocation"
          :options="suburboptionsList"
          :select-label="false"
          :deselect-label="false"
          placeholder="Location(Suburb)"
          label="name"
          track-by="name"
          :custom-label="nameWithSelect"
        >
        </multiselect> -->
        <vue-google-autocomplete
          ref="address"
          id="map-auto-compleate-location"
          classname="form-control"
          placeholder="Please type your suburb"
          v-on:placechanged="getAddressData"
          types="(cities)"
          country="au"
          @change="clearAutoCompleat"
        >
        </vue-google-autocomplete>
      </div>

      <div class="col-md-3">
        <button
          type="button"
          class="btn btn-success bg-primary search-btn"
          @click="onDetailCLickSearch"
        >
          Search
        </button>
      </div>
    </div>
    <div class="row"></div>
    <div class="row">
      <div class="col-12">
        <div class="container">
          <div class="row mb-3">
            <div class="col-lg-6 col-12 gutters mb-3 mt-5">
              <h2
                class="catTitle"
                v-if="catTitle == 'lgbtqai specific services'"
              >
                LGBTQAI+ Specific Services
              </h2>
              <h2 v-else class="catTitle">{{ catTitle }}</h2>
            </div>
            <div
              class="col-lg-6 col-12 gutters d-flex flex-column justify-content-center align-items-start"
            >
              <!-- <a--
                class="ml-md-auto singleBtn"
                href="https://wayouthservicesdirectory.org.au/"
                >Return to search11</a-->
              <a
                class="ml-md-auto singleBtn"
                href="https://wayouthsd.linux.hostaway.net.au/"
                >Return to search</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 gutters d-lg-block d-none">
              <div class="fixed-google-map mb-lg-5">
                <GmapMap
                  ref="mapRef"
                  :center="center"
                  :zoom="zoom"
                  style="width: 100%; height: 400px"
                  :options="mapStyle"
                >
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                  >
                    <div class="info-window">
                      <p class="mb-0">
                        <b>{{ infoContent.name }}</b>
                      </p>
                      <p class="mb-0">Phone: {{ infoContent.phone }}</p>
                      <span v-if="infoContent.address"
                        ><a
                          :href="
                            `https://maps.google.com/maps?daddr=${infoContent.address}`
                          "
                          >Get Directions</a
                        ></span
                      >
                      <span v-else>Contact Service for Address</span>
                    </div>
                  </gmap-info-window>

                  <GmapMarker
                    :key="index"
                    v-for="(m, index) in filteredItems"
                    :position="{ lat: Number(m.newlat), lng: Number(m.newlng) }"
                    :clickable="true"
                    @click="toggleInfoWindow(m, index)"
                    :id="m.id"
                    ref="marker"
                    :icon="getMarkers(index)"
                  />
                </GmapMap>
                <div
                  class="d-flex flex-row align-items-center justify-content-start"
                  ref="okBtn"
                  @click="locatorButtonPressed"
                >
                  <span><small class="font-weight-bold">Locate Me</small></span>
                  <span class="material-icons"> travel_explore </span>
                </div>

                <div class="search d-flex flex-row w-100 mt-3">
                  <div class="col-4">
                    <p class="mb-0">
                      <small class="font-weight-bold"
                        >Show Services Near Me</small
                      >
                    </p>
                  </div>
                  <div class="col-8">
                    <div class="range-slider-holder">
                      <input
                        type="range"
                        min="0"
                        max="20"
                        id="distance"
                        class="range-slider"
                        v-model="radius"
                        step="5"
                        @change="calculateReturn"
                      />
                      <output
                        for="distance"
                        :value="radius"
                        class="range-slider-tooltip"
                        :style="{ left: positionCalc + 'px' }"
                      >
                        <span class="mr-2">&laquo;</span>
                        <span v-if="radius <= 4">Under 5KM</span>
                        <span v-else-if="radius >= 20"
                          >Over {{ radius }}KM
                        </span>
                        <span v-else>WITHIN {{ radius }}KM</span>
                        <span class="ml-2">&raquo;</span>
                      </output>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-12 gutters">
              <div class="store-list left">
                <div class="col-12 border-dotted">
                  <div
                    class="row d-flex flex-row align-items-center justify-content-between gutters head-row pb-3 mb-3"
                  >
                    <h5 class="mb-0">
                      <span class="font-weight-bold mb-0">Services found </span
                      ><span v-if="totalItems === 0" class="text-primary">{{
                        filteredItems.length
                      }}</span
                      ><span class="text-primary" v-else>{{ totalItems }}</span>
                    </h5>
                    <div
                      class="openSwitch d-flex flex-row align-items-center justify-content-center"
                    >
                      <span class="mr-2">Open Now</span>
                      <label class="switch">
                        <input type="checkbox" @click="toggleCheckbox" />
                        <div class="switchSlider round"></div>
                      </label>
                    </div>
                  </div>
                  <div class="row w-100 gutters head-row pb-3">
                    <div class="col-lg-4 col-12">
                      <p class="mb-0">
                        <span class="font-weight-bold mb-0"
                          >Advanced filter</span
                        >
                      </p>
                    </div>
                    <div
                      class="col-lg-8 col-12 d-flex flex-row justify-content-between align-items-center"
                    >
                      <p
                        class="mb-0 d-flex flex-row justify-content-center align-items-center sortby"
                        @click="sort('title')"
                        :class="[sortBy === 'title' ? sortDirection : '']"
                      >
                        <span
                          v-if="sortDirection === '' || sortDirection === 'asc'"
                          >A-Z</span
                        ><span v-else
                          >Z-A
                          <span class="material-icons less"
                            >expand_less</span
                          ></span
                        >
                      </p>
                      <div class="mb-0 dropdown-head" ref="distBtn">
                        <div
                          class="aselect"
                          :data-value="value"
                          :data-list="list"
                          @click="toggle()"
                        >
                          <div class="selector">
                            <div
                              class="label d-flex flex-row justify-content-center align-items-center"
                            >
                              <span>Distance</span>
                              <span class="material-icons">expand_more</span>
                            </div>
                            <div
                              class="distanceMenu text-center"
                              :class="{ hidden: !visible, visible }"
                            >
                              <ul
                                class="d-flex flex-column justify-content-center w-100 align-items-center"
                              >
                                <li
                                  :class="{ current: item === value }"
                                  v-for="(item, index) in list"
                                  :key="index"
                                  @click="select(item)"
                                >
                                  {{ item }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p
                        class="mb-0 d-flex flex-row justify-content-center align-items-center sortby"
                        @click="sort('serviceIntake')"
                        :class="[
                          sortBy === 'serviceIntake' ? sortDirection : '',
                        ]"
                      >
                        Intakes<span class="material-icons more"
                          >expand_more</span
                        ><span class="material-icons less">expand_less</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(service, index) in filteredItems"
                  :key="service.id"
                  class="side-list"
                >
                  <Item
                    :service="service"
                    :distance="service.distance"
                    @clicked="onClickChild"
                    :index="index"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import LiveTracker from "@/components/LiveTracker.vue";
import Item from "@/components/Item.vue";
import Multiselect from "vue-multiselect";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    LiveTracker,
    Item,
    Multiselect,
    VueGoogleAutocomplete,
  },
  computed: {
    gmapApi,
  },
  name: "Category",
  props: [],
  data() {
    let mapMarker =
      "https://wayouthservicesdirectory.org.au/wp-content/uploads/2021/07/ways-map-marker1.png";
    let mapMarkerActive =
      "https://wayouthservicesdirectory.org.au/wp-content/uploads/2021/08/ways-map-marker2.png";
    return {
      mapMarker,
      mapMarkerActive,
      selectedKey: null,
      selectedMarker: null,
      sortBy: "name",
      sortDirection: "",
      isActive: true,
      active: false,
      checkbox: false,
      center: {
        lat: -31.949003199248203,
        lng: 115.86049272274552,
      },
      currentPlace: null,
      markers: [],
      place: null,
      radius: 5,
      lat: 0,
      lng: 0,
      range: 5,
      zoom: 12,
      distance: 0,
      infoContent: {},
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      positionCalc: "",
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      placeholder: "",
      mapStyle: {
        draggable: true,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        animation: 2,
        disableDefaultUI: true,
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 17 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#dedede" }, { lightness: 21 }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#ffffff" },
              { lightness: 16 },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              { saturation: 36 },
              { color: "#333333" },
              { lightness: 40 },
            ],
          },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#fefefe" }, { lightness: 20 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
          },
        ],
      },
      markerIcon:
        "https://wayouthservicesdirectory.org.au/wp-content/uploads/2021/07/ways-map-marker1.png",
      catTitle: "",
      value: "Select a Fruit",
      list: ["1-3km", "1-5km", "1-10km", "10-20km", "20km+"],
      visible: false,
      totalItems: 0,
      services: [],
      locationsuburbList: [],
      locationSuburbUniq: [],
      suburboptionsList: [],
      servicesJson: "",
      form: { valuelocation: "" },
      categories: [],
      servicesList: [],
      servicesFilteredList: [],
    };
  },

  computed: {
    filteredItems: function() {
      console.log("function call 1");
      return this.services
        .filter((service) => {
          return (
            service.catSlug.includes(this.$route.params.org) ||
            service.slugOrg == this.$route.params.org
          );
        })
        .sort((p1, p2) => {
          let modifier = 1;
          if (this.sortDirection === "desc") modifier = -1;
          if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
          if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
          return 0;
        });
    },
  },

  methods: {
    sort: function(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },

    toggle() {
      this.visible = !this.visible;
      this.services.forEach((service) => {
        let center = new google.maps.LatLng(this.center);
        let targetLoc = new google.maps.LatLng({
          lat: Number(service.newlat),
          lng: Number(service.newlng),
        });
        let distance = google.maps.geometry.spherical.computeDistanceBetween(
          center,
          targetLoc
        );
        service["distance"] = Math.ceil(Number(distance) * 0.001);
      });
    },

    select(option) {
      this.value = option;
      this.filteredItems.sort((a, b) => {
        return a.distance - b.distance;
      });

      this.countItems();
    },

    countItems() {
      var divs = document.querySelectorAll("[distance]"),
        i;
      for (i = 0; i < divs.length; ++i) {
        const attrTotal = divs[i].getAttribute("distance");
        //  divs[i].className += " hideItem";
        if (this.value == "1-3km") {
          divs[i].classList.remove("hideItem");
          this.zoom = 14;
          if (attrTotal > 2) {
            divs[i].className += " hideItem";
          }
        } else if (this.value == "1-5km") {
          this.zoom = 12;
          divs[i].classList.remove("hideItem");
          if (attrTotal > 5) {
            divs[i].className += " hideItem";
          }
        } else if (this.value == "1-10km") {
          this.zoom = 10;
          divs[i].classList.remove("hideItem");
          if (attrTotal > 10) {
            divs[i].className += " hideItem";
          }
        } else if (this.value == "10-20km") {
          divs[i].classList.remove("hideItem");
          this.zoom = 9;
          if (attrTotal > 15) {
            divs[i].className += " hideItem";
          }
        } else {
          this.zoom = 7;
          divs[i].classList.remove("hideItem");
        }
      }
      // const hidden = document.querySelector('.side-list-item').querySelectorAll('.hideItem');
      const hidden = document.querySelectorAll(".side-list-item.hideItem")
        .length;
      const seen = this.filteredItems.length;
      this.totalItems = seen - hidden;
      console.log(this.totalItems);
    },

    toggleCheckbox() {
      this.checkbox = !this.checkbox;
      this.$emit("setCheckboxVal", this.checkbox);
      // console.log(this.checkbox)
      if (this.checkbox === true) {
        document
          .querySelectorAll("[data='Closed']")
          .forEach((el) => (el.className += " hideItem"));
      }
      if (this.checkbox === false) {
        document
          .querySelectorAll("[data='Closed']")
          .forEach((el) => el.classList.remove("hideItem"));
      }
    },

    onClickChild(value) {
      if (value) {
        this.selectedKey = value.id;
        this.infoWindowPos = value;
        this.infoWinOpen = true;
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.zoom = 12;
          map.panTo(value);
          this.infoContent = {
            name: value.title,
            phone: value.telephone,
            address: value.address,
            id: value.index,
          };
        });
      } else {
        this.infoWinOpen = false;
      }
    },

    getMarkers(index) {
      if (this.selectedKey === index) return this.mapMarkerActive;
      return this.mapMarker;
    },
    toggleInfoWindow(service, idx) {
      (this.infoWindowPos = {
        lat: Number(service.newlat),
        lng: Number(service.newlng),
      }),
        (this.infoContent = {
          name: service.title,
          phone: service.telephone,
          address: service.address,
        });
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    calculateReturn() {
      var divs = document.querySelectorAll("[distance]"),
        i;
      for (i = 0; i < divs.length; ++i) {
        const attrTotal = divs[i].getAttribute("distance");
        //  divs[i].className += " hideItem";
        if (this.radius == 0) {
          divs[i].classList.remove("hideItem");
          this.zoom = 14;
          if (attrTotal > 2) {
            divs[i].className += " hideItem";
          }
        } else if (this.radius == 5) {
          this.zoom = 12;
          divs[i].classList.remove("hideItem");
          if (attrTotal > 5) {
            divs[i].className += " hideItem";
          }
        } else if (this.radius == 10) {
          this.zoom = 10;
          divs[i].classList.remove("hideItem");
          if (attrTotal > 10) {
            divs[i].className += " hideItem";
          }
        } else if (this.radius == 15) {
          divs[i].classList.remove("hideItem");
          this.zoom = 9;
          if (attrTotal > 15) {
            divs[i].className += " hideItem";
          }
        } else {
          this.zoom = 7;
          divs[i].classList.remove("hideItem");
        }
        const hidden = document.querySelectorAll(".side-list-item.hideItem")
          .length;
        const seen = this.servicesList.length;
        this.totalItems = seen - hidden;
        console.log(this.totalItems);
      }

      this.convertRadius();
    },

    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },

    locatorButtonPressed() {
      this.geolocate();
      this.zoom = 14;
      // console.log(this.center)
      this.$refs.mapRef.$mapPromise.then((map) => {
        // map.panTo({lat: 39.9042, lng: 116.4074})
        this.zoom = 16;
        map.panTo(this.center);
      });
      setTimeout(() => {
        this.services.forEach((service) => {
          let center = new google.maps.LatLng(this.center);
          let targetLoc = new google.maps.LatLng({
            lat: Number(service.newlat),
            lng: Number(service.newlng),
          });
          let distance = google.maps.geometry.spherical.computeDistanceBetween(
            center,
            targetLoc
          );
          service["distance"] = Math.ceil(Number(distance) * 0.001);
        });
        // console.log('shot my shot')
      }, 100);
    },

    convertRadius() {
      this.radius = Number(this.radius);
    },

    filterMarkers() {
      this.services = this.services.filter(
        (service) => service.distance < this.radius
      );
    },

    sortMarkers() {
      this.services.sort((a, b) => a.distance - b.distance);
    },
    updateLocations() {
      this.convertRadius();
      this.zoom = 7;
    },
    searchServices() {
      this.servicesJson.forEach((serviceData) => {
        this.servicesFilteredList.push(serviceData);
        this.servicesList = [...new Set(this.servicesFilteredList)];
        //console.log(this.servicesFilteredList);
        //Location suburb added
        this.locationsuburbList.push(serviceData.suburb);
        this.categories.push(serviceData.categories);
      });

      this.locationsuburbList = this.locationsuburbList.sort();
      const locationSuburbUniq = [...new Set(this.locationsuburbList)];
      //Suburb list of data push in suburboptions
      this.suburboptionsList = locationSuburbUniq.map((suburb) => {
        return { name: suburb };
      });
    },

    onDetailCLickSearch() {
      let subrubLocationCheck = false;
      let categoryCheck = false;
      this.services = [];
      // console.log(this.form.valuelocation);
      let result = this.servicesList.filter((item) => {
        // Using lower case category matched
        let categoryCheckData = item.categories.map((value) => {
          return value.toLowerCase() == this.catTitle;
        });
        categoryCheck = categoryCheckData.includes(true);

        if (this.form.valuelocation != "" && this.form.valuelocation != null) {
          subrubLocationCheck = item.suburb.trim() == this.form.valuelocation;
        }

        if (this.form.valuelocation != "" && this.form.valuelocation != null) {
          return categoryCheck && subrubLocationCheck;
        } else if (
          this.form.valuelocation == "" ||
          this.form.valuelocation == null
        ) {
          return categoryCheck;
        }
      });
      this.services = result;
      // console.log(this.services);
    },
    nameWithSelect({ name }) {
      return `${name}`;
    },
    clearAutoCompleat() {
      let locationInput = document.getElementById("map-auto-compleate-location")
        .value;
      if (
        locationInput == "" ||
        typeof locationInput == "undefined" ||
        locationInput == null
      ) {
        this.form.valuelocation = "";
      }
    },
    getAddressData: function(addressData, placeResultData, id) {
      /*Get address using geocoder lat and lng */

      //Second Way two fetch suburb
      let fetchSuburblocation = this.getReverseGeocodingData(
        addressData.latitude,
        addressData.longitude,
        "map"
      );
      let suburbLocation = fetchSuburblocation.then((suburname) => {
        this.form.valuelocation = suburname.trim(); //suburb
        console.log(this.form.valuelocation);
      });
    },
    getReverseGeocodingData(lat, lng, type) {
      let latlng = new google.maps.LatLng(lat, lng);

      let geocoder = new google.maps.Geocoder();
      let promiseResult = new Promise(function(resolve, reject) {
        geocoder.geocode({ latLng: latlng }, function(results, status) {
          // called asynchronously
          if (status == google.maps.GeocoderStatus.OK) {
            if (type == "map") {
              console.log(results[0].formatted_address);
              let SuburbAddress = results[0].formatted_address.split(",");
              let Suburb = SuburbAddress[1]; //retrive the suburb
              resolve(Suburb);
            } else {
              console.log(results[0].formatted_address);
              let SuburbAddress = results[0].formatted_address.split(",");
              let Suburb = SuburbAddress[2]; //retrive the suburb
              resolve(Suburb);
            }
          } else {
            reject(status);
          }
        });
      });
      return promiseResult;
    },
  },
  watch: {
    radius: function() {
      let percentage = (100 / 20) * this.radius;
      let sliderWidth = document.querySelector(".range-slider").clientWidth;
      let tooltipWidth = document.querySelector(".range-slider-tooltip")
        .clientWidth;
      let calc = sliderWidth - tooltipWidth - 5;
      let positionCalc = (percentage / 100) * calc;
      this.positionCalc = positionCalc;
    },
  },

  async mounted() {
    const axios = require("axios");
    this.$refs.address.focus();
    (this.loading = true),
      // axios.get("http://localhost:5000/api/service")
      axios
        .get("service/?token=005182f96b3e68d0acc30fbccfc633d5")
        .then((response) => {
          this.services = response.data;
          // console.log(response.data);
          this.servicesJson = response.data;
        })
        .then((response) => {
          this.searchServices();
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          // console.log(this.loading)
          // console.log(this.servicesJson)
        });
  },

  created() {
    var string = this.$route.params.org;
    const catTitle = string.split("-").join(" ");
    this.catTitle = catTitle;
  },
};
</script>

<style>
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.dropdown-head {
  position: relative;
}

.toggleMenu {
  position: absolute;
  top: 2rem;
  z-index: 999;
  background: #f2f7f7;
  border-radius: 20px;
  cursor: pointer;
}

.distanceMenu {
  position: absolute;
  background: #f2f7f7;
  border-radius: 0 0 20px 20px;
  width: 150px;
  z-index: 45;
  transform: translateX(-10px);
}

.distanceMenu ul {
  padding: 0;
  list-style-type: none;
  cursor: pointer;
}
.distanceMenu ul li {
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.switchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switchSlider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .switchSlider {
  background-color: #00a8a5;
}

input:focus + .switchSlider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .switchSlider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switchSlider.round {
  border-radius: 34px;
}

.switchSlider.round:before {
  border-radius: 50%;
}

.hideItem {
  display: none;
}

.showItem {
  display: block;
}

.range-slider-holder {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.range-slider-holder p {
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0;
  padding: 18px 18px 18px 0;
}
.range-slider-holder p:last-child {
  padding: 18px 0 18px 18px;
}
.range-slider-tooltip {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: 0;
  left: 100px;
  background: #e5e5e5;
  border-radius: 32px;
  color: black;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0 1rem;
  pointer-events: none;
  border: 1px solid black;
}

.range-slider {
  width: 100%;
}
input[type="range"] {
  -webkit-appearance: none;

  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1.5rem;
  cursor: pointer;
  background: #00a8a5;
  border-radius: 32px;
}
input[type="range"]::-webkit-slider-thumb {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: #00a8a5;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #00a8a5;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1.2rem;
  cursor: pointer;
  background: #00a8a5;
  border-radius: 32px;
}
input[type="range"]::-moz-range-thumb {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: #00a8a5;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #e5e5e5;
  border: 0.2px solid #122c35;
  border-radius: 50%;
}
input[type="range"]::-ms-fill-upper {
  background: #e5e5e5;
  border: 0.2px solid #122c35;
  border-radius: 50%;
}
input[type="range"]::-ms-thumb {
  border: 3px solid #122c35;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #e5e5e5;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #e5e5e5;
}
.range-slider-result {
  padding: 16px 16px 16px 48px;
  margin: 0 auto 48px;
  border: 4px solid #122c35;
  text-align: center;
}

.desc .less,
.asc .more {
  display: none;
}
.desc .more,
.sortby.asc .less {
  display: block;
}

.sortby .less {
  display: none;
}

.distanceMenu ul li {
  padding: 0.2rem 0.5rem;
  border-bottom: 1px solid;
  width: 100px;
}

.distanceMenu ul li:last-child {
  border-bottom: 0;
}
</style>
