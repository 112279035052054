import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import * as VueGoogleMaps from 'vue2-google-maps'

import AllServices from '../views/AllServices.vue'
import Category from '../views/Category.vue'
import YouthDoctor from '../views/YouthDoctor.vue'

import LiveFeed from '../views/LiveFeed.vue'
import FourOFour from '../views/FourOFour.vue'


import NotFound from '@/components/NotFound.vue'

import vueScrollto from 'vue-scrollto'
import Multiselect from 'vue-multiselect'
import ToggleButton from 'vue-js-toggle-button'


Vue.use(VueRouter)

//Live Google Key
/* Vue.use(VueGoogleMaps, {
  load: {
  key: 'AIzaSyBw-Ld2uyp74D05cs6QOK3CVpnKcjxJ1e0',
  libraries: 'places,geometry'
  }
});*/

//QA Domain Google Key
Vue.use(VueGoogleMaps, {
  load: {
  key: 'AIzaSyA8Davw2TDlFHt68fmx1gRFTn0ugi6rYAI',
  libraries: 'places,geometry'
  }
});
Vue.use(vueScrollto)
Vue.use(Multiselect)
Vue.use(ToggleButton)

const routes = [
  {
	/* Temporary we have change wordpress url due to we are doing changes in locally so added wordpress url */  
  
    path: '/',
    name: 'Home',
    component: Home
  },
  {
	
   path: '/all-services',
    name: 'AllServices',
    component: AllServices
  },
  {
  
    path: '/live-feed',
    name: 'LiveFeed',
    component: LiveFeed
  },

  {
	  //Added path for seach result click and display more details result
 
   path: '/services/:org',
    name: 'Category',
    component: Category
  },

  {
   
    path: '/youth-doctors',
    name: 'YouthDoctor',
    component: YouthDoctor
  },
  
  // {
  //   path: 'service/:catchAll(.*)',
  //   name: 'Not Found',
  //   component: NotFound
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

// // router guards
// router.beforeEach((to, from, next) => {
//   // check to see if route has auth guard
//   if(to.matched.some(rec => rec.meta.requiresAuth)){
//     // check auth state of user
//     let user = auth.currentUser
//     if (user) {
//       // User is signed in. Proceed to route
//       next()
//     } else {
//       // No user is signed in. Redirect to login
//       next({
//         name: 'Login'
//       })
//     }
//   } else {
//     // if route is not guarded by auth, proceed
//     next()
//   }
// })

export default router
