<template>
  <div
    class="row w-100 trackerResults d-flex align-items-center"
    :data="dateBetweenDate()"
    @click="toggle()"
    @mouseover.passive="hover = true"
    @mouseleave.passive="hover = false"
    :class="{ active: hover }"
  >
    <div
      class="tracker-head d-flex flex-row w-100 align-items-center justify-content-center py-2"
      :class="isActive ? 'active' : 'not-active'"
    >
      <div
        class="col-3 font-weight-bold tracker-title"
        v-if="service.title.length < 30"
      >
        {{ service.title }}
      </div>
      <div class="col-3 font-weight-bold tracker-title text-capitalize" v-else>
        {{ service.title.substring(0, 30) + ".." }}
      </div>

      <div class="col-1 d-lg-block d-none">{{ service.suburb }}</div>

      <div class="col-lg-7 col-sm-8 col-9 text-center">
        <div class="row tracker-icons d-flex flex-row align-items-center">
          <div class="col-md-2 col-4">
            <span v-if="service.serviceStatus.includes('Operating as Normal')">
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-md-2 col-4">
            <span v-if="service.serviceStatus.includes('Reduced Staffing')">
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-md-2 col-4">
            <span v-if="service.serviceStatus.includes('Reduced Intake')">
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-2 d-md-block d-none">
            <span v-if="service.serviceStatus.includes('Online Phone Contact')">
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-2 d-md-block d-none">
            <span
              v-if="
                service.serviceStatus.includes('Face-to-Face(on premisise)')
              "
            >
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-2 d-md-block d-none">
            <span
              v-if="service.serviceStatus.includes('Face-to-Face (outreach)')"
            >
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
        </div>
      </div>
      <div class="col-1 updatedCol d-sm-block d-none">
        <span v-if="dateBetween === 0">Today</span
        ><span v-else>{{ dateBetween }} days ago</span>
      </div>
    </div>
    <div
      class="itemCollapsed w-100"
      :class="isActive ? 'active' : 'not-active'"
    >
      <div class="row logo-row py-3">
        <div class="col-lg-3 col-md-6 col-12 about-border gutters">
          <h4 class="font-weight-bold mb-3">{{ service.title }}</h4>
          <div v-if="service.image" class="service-img mb-2 d-md-block d-none">
            <img :src="service.image" />
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-12 service-preview about-border gutters"
        >
          <h6 class="font-weight-bold mb-0">Contact Details</h6>
          <p class=""></p>
          <p
            class="description d-flex flex-row align-iitems-center justify-content-start mb-0"
          >
            <span class="material-icons-outlined mr-2"> location_on </span
            >{{ service.streetNumber }} {{ service.street }}
            {{ service.suburb }} {{ service.postcode }}
          </p>
          <a
            v-if="service.email"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            :href="'mailto:' + service.email"
            ><span class="material-icons-outlined mr-2 no-underline">
              email </span
            >Email</a
          >
          <a
            v-if="service.telephone"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            :href="'tel:' + service.telephone"
            ><span class="material-icons-outlined mr-2 no-underline">
              phone </span
            >{{ service.telephone }}</a
          >
        </div>

        <div class="col-lg-3 col-md-6 col-12 about-border gutters">
          <p class="service-preview d-md-block d-none">
            {{ service.servicePreview }}
          </p>
          <a
            :href="
              `https://wayouthservicesdirectory.org.au/service/` + service.slug
            "
          >
            <a class="btn spot-btn mb-3">View Service3334</a>
          </a>
          <div
            v-if="service.crisisStatus"
            class="serviceCrisisHolder d-md-block d-none"
          >
            <p>{{ service.crisisStatus }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 d-md-block d-none gutters">
          <h6 class="font-weight-bold mb-1">Opening hours</h6>
          <div v-for="item in service.items" :key="item.id">
            <div
              class="row w-100 d-flex flex-row align-items-center justify-content-start open-row"
            >
              <div class="day mb-0 mr-2">{{ item.day }}</div>
              <div v-if="item.start" class="startTime">
                {{ item.start }} to {{ item.end }}
                <span v-if="item.end > '18:00' || item.id < 1 || item.id > 5"
                  >*</span
                >
              </div>
              <div v-else>Contact Service</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveFeedTracker",
  props: ["service"],
  data() {
    return {
      dateBetween: "",
      isActive: false,
      hover: false,
      dateBetween: "",
    };
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;
      // console.log(this.service.distance)
    },
    dateBetweenDate() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const updated = this.service.updated;
      const diff = Math.floor(
        (Date.parse(date) - Date.parse(updated)) / 86400000
      );

      this.dateBetween = diff;
    },
  },
};
</script>

<style>
.trackerResults {
  font-size: 12px;
  line-height: 22px;
  border-bottom: 1px solid #00a8a5;
}
.service-img img {
  max-height: 180px;
  max-width: 240px;
}

.updatedCol {
  font-size: 12px;
}
.tracker-icons .material-icons {
  color: #00a8a5;
}

.tracker-head {
  line-height: 1;
  min-height: 43px;
}

.tracker-head.active .tracker-icons .material-icons {
  color: #fff;
}
.tracker-head.active {
  background: #00a8a5;
  color: #fff;
}

.tracker-head.active .tracker-title {
  padding-left: 10px;
}

.open-row {
  border-top: 1px solid;
  border-bottom: 1px soliid;
}

.trackerResults.active .tracker-head {
  background: #00a8a5;
  color: #fff;
  cursor: pointer;
}

.trackerResults.active .tracker-title {
  padding-left: 10px;
}

.trackerResults.active .tracker-icons .material-icons {
  color: #fff;
}

.serviceCrisisHolder {
  background: rgba(0, 168, 165, .44;);
  line-height: 1.3;
}
</style>
