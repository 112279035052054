import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Search from './Search.vue'

import AdvanceSearchHome from './AdvanceSearchHome.vue'
import './assets/main.css'
import './assets/vue-multiselect.min.css'
//Set Global API baseURL Here
import axios  from 'axios'

//Prod
//axios.defaults.baseURL="https://wayouthservicesdirectory.org.au/";

//QA
axios.defaults.baseURL="https://wayouthsd.linux.hostaway.net.au/";

//Dev
//axios.defaults.baseURL="http://localhost:5000/api/";

Vue.config.productionTip = false
var one = new Vue({
  router,
  el: "#app",
  render: h => h(App)
})

var homesearch = new Vue({
  router,
  el: "#homesearch",
  render: h => h(AdvanceSearchHome)
})
var two = new Vue({
  router,
  el: "#vue-app-two",
  render: h => h(Search)
})




