<template>
  <div
    class="side-list-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ active: hover }"
    :data="passing"
    :distance="distance"
    :id="index"
  >
    <div
      class="single p-3"
      @mouseenter="onClickButton"
      :class="isActive ? 'dark-theme' : 'light-theme'"
    >
      <div class="info">
        <div class="row">
          <div class="col-12">
            <h5 class="service-title font-weight-bold pr-3">
              {{ service.title }}
            </h5>
            <ItemSvg class="mb-2" :serviceCategory="service.categories" />
            <p class="mb-2 service-call">Call: {{ service.telephone }}</p>
          </div>

          <div
            class="view-service position-relative"
            @mouseover="hoverMe"
            @mouseleave="unhoverMe"
            @click="toggle()"
          >
            <transition name="bounce">
              <div class="showInfo" v-if="show">
                <p class="mb-0"><small>Quick View</small></p>
              </div>
            </transition>
            <div id="cf">
              <div class="top" :class="isActive ? 'hide' : 'show'">
                <span class="material-icons-outlined">visibility</span>
              </div>
              <div class="bottom" :class="isActive ? 'show' : 'hide'">
                <span class="material-icons-outlined">close</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-row align-items-center">
          <div
            class="d-flex flex-row align-items-center openHoursBox col-md-4 col-6 mb-md-0 mb-2"
            :class="isActive ? 'active' : 'not-active'"
          >
            <span
              v-if="passing == 'Open Now'"
              class="material-icons mr-2 clock-green"
            >
              schedule
            </span>
            <span v-else class="material-icons mr-2 clock-red"> schedule </span>
            <div v-if="passing == 'Open Now'">Open</div>
            <div v-else>Closed</div>
          </div>
          <div class="col-md-4 col-6">
            <p
              class="mb-0 distance d-flex flex-row justify-content-center align-items-center"
              v-if="distance"
            >
              <span class="material-icons-outlined mr-1"> location_on </span
              >{{ distance }}km away
            </p>
          </div>
          <div class="ml-auto col-md-4 col-12 text-md-right text-left">
            <!--<a
              :href="`https://wayouthservicesdirectory.org.au/` + service.slug"
            >-->
            <a
              :href="`https://wayouthsd.linux.hostaway.net.au/` + service.slug"
            >
              <a class="btn spot-btn mb-3">View Service</a>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="itemCollapsed" :class="isActive ? 'active' : 'not-active'">
      <div class="row logo-row py-3">
        <div class="col-md-7 col-12 about-border gutters">
          <div v-if="service.image" class="service-img mb-2">
            <img :src="service.image" />
          </div>

          <h6 class="font-weight-bold mb-1">About {{ service.title }}</h6>
          <p class="service-preview">{{ service.servicePreview }}</p>
        </div>
        <div class="col-md-5 col-12 service-preview gutters">
          <h6 class="font-weight-bold mb-0">Contact Details</h6>
          <a
            v-if="service.telephone"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            :href="'tel:' + service.telephone"
            ><span class="material-icons-outlined mr-2 no-underline">
              phone </span
            >{{ service.telephone }}</a
          >
          <a
            v-if="service.email"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            :href="'mailto:' + service.email"
            ><span class="material-icons-outlined mr-2 no-underline">
              email </span
            >Email</a
          >
          <a
            v-if="service.website"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            target="_blank"
            :href="service.website"
            ><span class="material-icons-outlined mr-2 no-underline">
              wifi </span
            >Website</a
          >
          <p
            class="description d-flex flex-row align-iitems-center justify-content-start mb-0"
          >
            <span class="material-icons-outlined mr-2"> location_on </span
            >{{ service.streetNumber }} {{ service.street }}
            {{ service.suburb }} {{ service.postcode }}
          </p>
        </div>
      </div>
      <div v-if="service.serviceIntake" class="row bg-intake">
        <div class="col-12 p-3">
          <div
            class="text-white font-weight-bold mr-3 d-flex flex-row align-items-center"
          >
            <span
              v-if="service.serviceIntake === 'Open'"
              class="material-icons-outlined mr-2"
            >
              how_to_reg
            </span>
            <span
              v-else-if="service.serviceIntake === 'Closed'"
              class="material-icons-outlined mr-2"
            >
              cancel
            </span>
            <span v-else class="material-icons-outlined mr-2"> call </span>
            <span v-if="service.serviceIntake != 'NA'"
              >{{ service.serviceIntake }} for New intakes</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSvg from "@/components/ItemSvg.vue";
export default {
  name: "Item",
  props: ["service", "markers", "distance", "index"],
  components: {
    ItemSvg,
  },
  data() {
    return {
      isActive: false,
      hover: false,
      passing: "",
      serviceOpen: false,
      end: "",
      start: "",
      show: false,
      hovering: false,
      openHours: [],
      timeNow: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    hoverMe() {
      this.hovering = true;
      setTimeout(() => (this.show = this.hovering), 1500);
    },
    unhoverMe() {
      this.hovering = false;
      this.show = false;
    },

    toggle() {
      this.isActive = !this.isActive;
      // console.log(this.service.distance)
    },

    onClickButton(event) {
      this.$emit("clicked", {
        lat: this.service.newlat,
        lng: this.service.newlng,
        title: this.service.title,
        telephone: this.service.telephone,
        address: this.service.address,
        icon:
          "https://wayouthservicesdirectory.org.au/wp-content/uploads/2021/07/doctor.png",
        id: this.index,
      });
    },
    sortBy(prop) {
      this.service.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    openToggleList() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const time =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds();
      const day = current.getDay();
      const dateTime = time + " " + date + " " + day;
      const openHours = this.service.items;
      const title = this.service.title;
      const items = openHours.filter((item) => item.id.indexOf(day) !== -1);
      const currentHours = current.getHours();
      const currentHoursTrim = ("0" + currentHours).slice(-2);
      const timeNow = currentHoursTrim + ":" + current.getMinutes();
      const start = items[0].start;
      const end = items[0].end;

      if (start == null || end == null) {
        return (this.passing = "Open Now");
      } else if (timeNow <= end && timeNow >= start) {
        return (this.passing = "Open Now");
      } else {
        return (this.passing = "Closed");
      }
    },
  },

  created() {
    this.openToggleList();
  },
};
</script>

<style>
.showInfo {
  position: absolute;
  width: 120px;
  bottom: 100%;
  left: 0%;
  margin-left: -130px; /* Use half of the width (120/2 = 60), to center the tooltip */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 5;
}

.showInfo::after {
  content: " ";
  position: absolute;
  z-index: 5;
  top: 100%; /* At the bottom of the tooltip */
  right: 5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}
/* .bounce-leave-active {
  animation: bounce-in .5s reverse;
} */
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.side-list-item.active .itemCollapsed .logo-row {
  background: #fff;
  transition: 0.2s linear;
}

.openHoursBox {
  width: 120px;
}
.openHoursBox.active span {
  color: white;
}
</style>
