<template>
  <div
    class="trackerResults col-12"
    :data="dateBetweenDate()"
    @click="toggle()"
  >
    <div
      class="tracker-head d-flex flex-row w-100 align-items-center justify-content-center py-2"
      :class="isActive ? 'active' : 'not-active'"
    >
      <div class="col-3 font-weight-bold tracker-title">
        {{ service.title }}
      </div>
      <div class="col-9">
        <div
          class="row d-flex flex-row align-items-center justify-content-center"
        >
          <div class="col-md-3 col-4 text-center">
            <span v-if="service.serviceIntake.includes('Open')">
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-md-3 col-4 text-center">
            <span v-if="service.serviceIntake.includes('Closed')">
              <span class="material-icons">circle</span></span
            ><span v-else>&#42;</span>
          </div>
          <div class="col-md-3 col-4 text-center d-md-block d-none">
            <span> {{ service.telephone }}</span>
          </div>
          <div class="col-md-3 col-4 text-center">
            <span v-if="dateBetween === 0">Today</span
            ><span v-else>{{ dateBetween }} days ago</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="itemCollapsed w-100"
      :class="isActive ? 'active' : 'not-active'"
    >
      <div class="row logo-row py-3">
        <div class="col-lg-3 col-md-6 col-12 about-border gutters">
          <h4 class="font-weight-bold mb-3">{{ service.title }}</h4>
          <div v-if="service.image" class="service-img mb-2 d-md-block d-none">
            <img :src="service.image" />
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-12 service-preview about-border gutters"
        >
          <h6 class="font-weight-bold mb-0">Contact Details</h6>

          <p class=""></p>
          <p
            class="description d-flex flex-row align-iitems-center justify-content-start mb-0"
          >
            <span class="material-icons-outlined mr-2"> location_on </span
            >{{ service.streetNumber }} {{ service.street }}
            {{ service.suburb }} {{ service.postcode }}
          </p>
          <a
            v-if="service.email"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            :href="'mailto:' + service.email"
            ><span class="material-icons-outlined mr-2 no-underline">
              email </span
            >Email</a
          >
          <a
            v-if="service.telephone"
            class="item-collapse-contact mb-0 d-flex align-items-center justify-content-start flex-row"
            :href="'tel:' + service.telephone"
            ><span class="material-icons-outlined mr-2 no-underline">
              phone </span
            >{{ service.telephone }}</a
          >
        </div>

        <div class="col-lg-3 col-md-6 col-12 about-border gutters">
          <p class="service-preview d-md-block d-none">
            {{ service.servicePreview }}
          </p>
          <a
            :href="
              `https://wayouthservicesdirectory.org.au/service/` + service.slug
            "
          >
            <a class="btn spot-btn mb-3">View Service333</a>
          </a>
          <div
            v-if="service.crisisStatus"
            class="serviceCrisisHolder d-md-block d-none"
          >
            <p>{{ service.crisisStatus }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 d-md-block d-none gutters">
          <h6 class="font-weight-bold mb-1">Opening hours</h6>
          <div v-for="item in service.items" :key="item.id">
            <div
              class="row w-100 d-flex flex-row align-items-center justify-content-start open-row"
            >
              <div class="day mb-0 mr-2">{{ item.day }}</div>
              <div v-if="item.start" class="startTime">
                {{ item.start }} to {{ item.end }}
                <span v-if="item.end > '18:00' || item.id < 1 || item.id > 5"
                  >*</span
                >
              </div>
              <div v-else>Contact Service</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveTracker",
  props: ["service"],
  data() {
    return {
      dateBetween: "",
      isActive: false,
      hover: false,
      dateBetween: "",
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      // console.log(this.service.distance)
    },
    dateBetweenDate() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const updated = this.service.updated;

      const diff = Math.floor(
        (Date.parse(date) - Date.parse(updated)) / 86400000
      );

      this.dateBetween = diff;
    },
  },
  computed: {
    sortedArray: function() {
      function updated(a, b) {
        if (a.updated > b.updated) return -1;
        if (a.updated < b.updated) return 1;
        return 0;
      }
      // return this.users.sort(surnameName); // sorts in-place
      return [...this.services].sort(updated); // shallow clone + sort
    },
  },
  created() {
    // console.log(this.services)
  },
};
</script>

<style>
.liveTracker {
  background: #fff;
  box-shadow: 1px 1px 5px 0px #888888b0;
  z-index: 3;
}

.live-tracker-single:nth-child(2) {
  border-bottom: 1px dotted;
}

.ls-boxes > div p,
.ls-boxes > div button {
  font-size: 15px;
  line-height: 1;
}

.ls-boxes .box {
  text-align: center;
}

.viewService-blk {
  background: #fff;
  color: #000;
  transition: 0.2s linear;
  border: 2px solid #000;
  border-radius: 100px;
  padding: 0.4rem 0.6rem;
}

.lt-logo {
  max-width: 199px;
  max-height: 54px;
  width: 100%;
  object-fit: cover;
}
.green-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10rem 200vw 10rem 0;
  border-color: transparent #00a8a5 transparent transparent;
  position: absolute;
  right: 0;
  z-index: 0;
}

.grey-triangle {
  border-style: solid;
  border-width: 10rem 200vw 10rem;
  border-color: transparent transparent transparent #f2f7f7;
  position: absolute;
  left: 0;
  z-index: -1;
}
</style>
