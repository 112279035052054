<template>
  <div id="advancesearchhome">
    <div class="row">
      <div class="col-12">
        <div class="row col-md-12 search-section">
          <div class="col-md-3">
            <multiselect
              v-model="form.category"
              :options="options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Categories"
              label="name"
              track-by="name"
              :showLabels="false"
              :preselect-first="true"
              @open="closeResult"
            >
              <!--<template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.name }} category name</span
                ></template>-->
            </multiselect>
          </div>
          <div class="col-md-3">
            <div>
              <vue-google-autocomplete
                ref="address"
                id="map-auto-compleate"
                classname="form-control"
                placeholder="Please type your suburb"
                v-on:placechanged="getAddressData"
                types="(cities)"
                country="au"
                @change="clearAutoCompleat"
              >
              </vue-google-autocomplete>
            </div>
          </div>

          <!--<div class="col-md-3">
            <multiselect
              v-model="form.location"
              :options="suburboptions"
              :select-label="false"
              :deselect-label="false"
              placeholder="Location(Suburb)"
              label="name"
              track-by="name"
              @open="closeResult"
              :custom-label="nameWithSelect"
            ></multiselect>
          </div>-->

          <div class="col-md-2">
            <toggle-button
              @change="onChangeEventHandler"
              :labels="{
                checked: 'Virtual Service On',
                unchecked: 'Virtual Service Off',
              }"
              :width="140"
              :height="35"
              color="#00a8a5"
              :value="virtualServiceToggle"
              v-model="form.isvirtualEnable"
            />
          </div>

          <div class="col-md-1">
            <button
              type="button"
              class="btn btn-success bg-primary search-btn"
              @click="SearchData"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!--<div class="col-md-2">
          <h6 class="mb-0">
            <span class="font-weight-bold mb-0">Total Services : </span
            ><span v-if="totalItems === 0" class="text-primary">{{
              homeSearchData.length
            }}</span
            ><span class="text-primary" v-else>{{ totalItems }}</span>
          </h6>
        </div>-->
        <div class="col-md-4" ref="okBtn" @click="locatorButtonPressed">
          <span><small class="font-weight-bold">Locate Me</small></span>
          <span class="material-icons"> travel_explore </span>
        </div>
        <div class="col-md-4">
          <small class="font-weight-bold">Show Services Near Me</small>
          <div class="range-slider-holder">
            <input
              type="range"
              min="0"
              max="20"
              id="distance"
              class="range-slider"
              v-model="radius"
              step="5"
              @change="calculateReturn"
            />
            <output
              for="distance"
              :value="radius"
              class="range-slider-tooltip"
              :style="{ left: positionCalc + 'px' }"
            >
              <span class="mr-2">&laquo;</span>
              <span v-if="radius <= 4">Under 5KM</span>
              <span v-else-if="radius >= 20">Over {{ radius }}KM </span>
              <span v-else>WITHIN {{ radius }}KM</span>
              <span class="ml-2">&raquo;</span>
            </output>
          </div>
        </div>
      </div>
    </div>

    <!--Filtered Result -->
    <div class="fixed-google-map mb-lg-5">
      <GmapMap
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        style="width: 100%; height: 200px"
        :options="mapStyle"
      >
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div class="info-window">
            <p class="mb-0">
              <b>{{ infoContent.name }}</b>
            </p>
            <p class="mb-0">Phone: {{ infoContent.phone }}</p>
            <span v-if="infoContent.address"
              ><a
                :href="
                  `https://maps.google.com/maps?daddr=${infoContent.address}`
                "
                >Get Directions</a
              ></span
            >
            <span v-else>Contact Service for Address</span>
          </div>
        </gmap-info-window>

        <GmapMarker
          :key="index"
          v-for="(m, index) in homeSearchData"
          :position="{ lat: Number(m.newlat), lng: Number(m.newlng) }"
          :clickable="true"
          @click="toggleInfoWindow(m, index)"
          :id="m.id"
          ref="marker"
          :icon="getMarkers(index)"
        />
      </GmapMap>
    </div>

    <div class="serviceResultsRow" style="z-index: 9999;">
      <div class="col-sm-12 list-group" style="width: 100%;">
        <div class="row">
          <div id="emptyResult" class="col-md-12"></div>
        </div>
        <div
          v-if="homeSearchData.length > 0 || similarSearchData.length > 0"
          class="row"
        >
          <div class="col-sm-12">
            <button
              type="button"
              class="close"
              @click="closeSearchButton"
              style="float:right"
              title="Close"
            >
              X
            </button>
          </div>
          <div
            class="list-group "
            style="width: 100%;"
            v-if="homeSearchData.length < 1"
          >
            <div class="list-group-item bg-primary text-white">
              <h4>Exact Result not Found.</h4>
            </div>
          </div>
          <div
            v-for="(service, index) in homeSearchData"
            :key="service.id"
            class="col-md-6 side-list"
            style="padding:2px;"
          >
            <Item
              :service="service"
              :distance="service.distance"
              @clicked="onClickChild"
              :index="index"
            />
          </div>

          <div
            class="list-group "
            style="width: 100%;"
            v-if="similarSearchData.length > 0"
          >
            <div class="list-group-item bg-primary text-white">
              <h4>{{ similarSearchData.length }} Similar Results Found.</h4>
            </div>
            <div
              v-for="(service, index) in similarSearchData"
              :key="service.id"
              class="col-md-6 side-list"
              style="padding:2px;"
            >
              <Item
                :service="service"
                :distance="service.distance"
                @clicked="onClickChild"
                :index="index"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
import json from "@/stores/ServiceStore.json";
import Multiselect from "vue-multiselect";
import ToggleButton from "vue-js-toggle-button";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Item from "@/components/ItemSearchPreview.vue";

export default {
  name: "AdvanceSearchHome",

  components: { Multiselect, VueGoogleAutocomplete, Item },
  props: [],
  data() {
    return {
      servicesJson: "",
      search: "",
      services: [],
      titles: [],
      searchValue: "",
      categories: [],
      orgs: [],
      join: [],
      arrFiltered: [],
      slug: "",
      catUniq: [],
      bigArray: [],
      simpleArr: [],
      validName: false,
      componentLoaded: false,
      place: null,
      checkedNames: [],
      options: [],
      suburboptions: [],
      locationsuburb: [],
      locationSuburbUniq: [],
      virtualServiceToggle: false,
      form: { category: [], location: "", address: "", isvirtualEnable: false },
      homeSearchData: [],
      homeService: [],
      similarSearchData: [],
      /*New Map changes added */
      center: {
        lat: -31.949003199248203,
        lng: 115.86049272274552,
      },
      focusLocation: { lat: "", lng: "" },

      radius: 5,
      lat: 0,
      lng: 0,
      range: 5,
      zoom: 12,
      distance: 0,
      positionCalc: "",
      totalItems: 0,
      infoContent: {},
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      positionCalc: "",
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      mapStyle: {
        draggable: true,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        animation: 2,
        disableDefaultUI: true,
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 17 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#dedede" }, { lightness: 21 }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#ffffff" },
              { lightness: 16 },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              { saturation: 36 },
              { color: "#333333" },
              { lightness: 40 },
            ],
          },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#fefefe" }, { lightness: 20 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
          },
        ],
      },
    };
  },

  beforeMount() {},
  computed: {
    // filterByTerm() {
    //   return this.join.filter((title) => {
    //     return title.name.toLowerCase().match(this.searchValue.toLowerCase());
    //   });
    // },
  },
  methods: {
    setPlace(place) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.place = place;
      console.log(lng);
      console.log(lat);
    },

    searchServices() {
      this.servicesJson.forEach((service) => {
        let arrFiltered = [];
        let services = [];
        let titles = [];
        let categories = [];
        this.services.push(service);

        // console.log(service.suburb);
        //Location suburb added
        this.locationsuburb.push(service.suburb);

        this.categories.push(service.categories);
        this.titles.push({
          name: service.title,
          type: "Service Name",
          slug: service.slug,
          address: service.address,
          newlat: service.newlat,
          newlng: service.newlng,
          title: service.title,
          telephone: service.telephone,
          items: service.items,
          streetNumber: service.streetNumber,
          street: service.street,
          suburb: service.suburb,
          postcode: service.postcode,
          website: service.website,
          serviceIntake: service.serviceIntake,
          categories: service.categories,
        });
        this.orgs.push({
          name: service.org,
          type: "Organisation",
          slug: service.slugOrg,
        });
        this.bigArray = this.categories.flat();
      });
      //Added sort array
      this.locationsuburb = this.locationsuburb.sort();
      this.bigArray = this.bigArray.sort();
      const catUniq = [...new Set(this.bigArray)];
      const locationSuburbUniq = [...new Set(this.locationsuburb)];

      //Categories list of data push in options
      this.options = catUniq.map((value) => {
        return { name: value };
      });

      //Suburb list of data push in suburboptions
      this.suburboptions = locationSuburbUniq.map((suburb) => {
        return { name: suburb };
      });

      let simpleArr = [];
      catUniq.forEach((category) => {
        let slugCat = slugify(category, {
          replacement: "-",
          remove: /[$*_,+~.()'"!\-:@]/g,
          lower: true,
        });
        // console.log(slugCat);
        this.simpleArr.push({
          name: category,
          type: "Category Search",
          slug: slugCat,
        });
      });
      //  console.log(this.simpleArr)
      this.orgs = [...new Set(this.orgs)];
      var result = this.orgs.reduce((unique, o) => {
        if (!unique.some((obj) => obj.name === o.name && obj.type === o.type)) {
          unique.push(o);
        }
        return unique;
      }, []);

      this.arrFiltered = result;
      this.join = this.arrFiltered.concat(this.titles, this.simpleArr);
      // console.log(this.join);
    },
    onChangeEventHandler() {
      this.virtualServiceToggle = !this.virtualServiceToggle;
      console.log("change event handler" + this.virtualServiceToggle);
    },
    SearchData() {
      let category_check = false;
      let subrub_check = false;
      let virtual_check = false;
      this.homeSearchData = [];
      this.similarSearchData = [];
      let removeItemArr = [];

      let categoriesArr = this.form.category.map((cat) => {
        return cat.name;
      });
      document.getElementById("emptyResult").innerHTML = "";

      /* Map focus on currrent suburb location */
      if (this.focusLocation.lat !== "" && this.focusLocation.lng !== "") {
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.zoom = 12;
          map.panTo(this.focusLocation);
        });
      }

      let result = this.services.filter((item, key) => {
        if (this.form.category.length > 0) {
          //category_check = this.form.category.some((cat) => item.categories.includes(cat.name));
          category_check = categoriesArr.every((ai) =>
            item.categories.includes(ai)
          );
        }
        if (this.form.address != "" && this.form.address != null) {
          subrub_check = item.suburb.trim() == this.form.address;
        }
        virtual_check =
          this.form.isvirtualEnable == item.virtualService ? true : false;
        //Handle the three field conditional logic
        if (
          this.form.address != "" &&
          this.form.address != null &&
          this.form.category.length > 0
        ) {
          if (category_check && subrub_check && virtual_check)
            removeItemArr.push(key);
          return category_check && subrub_check && virtual_check;
        } else if (
          (this.form.address == "" || this.form.address == null) &&
          this.form.category.length < 1
        ) {
          if (virtual_check) removeItemArr.push(key);

          return virtual_check;
        } else if (this.form.address == "" || this.form.address == null) {
          if (category_check && virtual_check) removeItemArr.push(key);
          return category_check && virtual_check;
        } else {
          if (subrub_check && virtual_check) removeItemArr.push(key);
          return subrub_check && virtual_check;
        }
      });

      result.forEach((dataList) => {
        this.homeSearchData.push({
          name: dataList.title,
          type: "Service Name",
          slug: dataList.slug,
          address: dataList.address,
          newlat: dataList.newlat,
          newlng: dataList.newlng,
          title: dataList.title,
          telephone: dataList.telephone,
          items: dataList.items,
          streetNumber: dataList.streetNumber,
          street: dataList.street,
          suburb: dataList.suburb,
          postcode: dataList.postcode,
          website: dataList.website,
          serviceIntake: dataList.serviceIntake,
          categories: dataList.categories,
        });
      });

      let similarResult = this.services.filter((item, key) => {
        if (removeItemArr.includes(key)) return false;
        if (this.form.category.length > 0) {
          category_check = this.form.category.some((cat) =>
            item.categories.includes(cat.name)
          );
        }

        if (this.form.address != "" && this.form.address != null) {
          subrub_check = item.suburb.trim() == this.form.address;
        }
        virtual_check =
          this.form.isvirtualEnable == item.virtualService ? true : false;
        //Handle the three field conditional logic
        if (
          this.form.address != "" &&
          this.form.address != null &&
          this.form.category.length > 0
        ) {
          return category_check && subrub_check && virtual_check;
        } else if (
          (this.form.address == "" || this.form.address == null) &&
          this.form.category.length < 1
        ) {
          return virtual_check;
        } else if (this.form.address == "" || this.form.address == null) {
          return category_check && virtual_check;
        } else {
          return subrub_check && virtual_check;
        }
      });

      similarResult.forEach((dataList) => {
        this.similarSearchData.push({
          name: dataList.title,
          type: "Service Name",
          slug: dataList.slug,
          address: dataList.address,
          newlat: dataList.newlat,
          newlng: dataList.newlng,
          title: dataList.title,
          telephone: dataList.telephone,
          items: dataList.items,
          streetNumber: dataList.streetNumber,
          street: dataList.street,
          suburb: dataList.suburb,
          postcode: dataList.postcode,
          website: dataList.website,
          serviceIntake: dataList.serviceIntake,
          categories: dataList.categories,
        });

        let center = new google.maps.LatLng(this.center);
        let targetLoc = new google.maps.LatLng({
          lat: Number(dataList.newlat),
          lng: Number(dataList.newlat),
        });

        let distance = google.maps.geometry.spherical.computeDistanceBetween(
          center,
          targetLoc
        );
        dataList["distance"] = Math.ceil(Number(distance) * 0.001);
      });

      console.log(this.homeSearchData);
      document.getElementById("emptyResult").classList.remove("bg-primary");
      document
        .getElementById("emptyResult")
        .classList.remove("list-group-item");
      if (this.homeSearchData.length < 1 && this.similarSearchData.length < 1) {
        document.getElementById("emptyResult").classList.add("bg-primary");
        document.getElementById("emptyResult").classList.add("list-group-item");
        document.getElementById("emptyResult").innerHTML = "No result Found";
      }
    },
    closeResult() {
      this.homeSearchData = [];
      this.similarSearchData = [];
      document.getElementById("emptyResult").innerHTML = "";
      document.getElementById("emptyResult").classList.remove("bg-primary");
      document
        .getElementById("emptyResult")
        .classList.remove("list-group-item");
    },
    closeSearchButton() {
      this.homeSearchData = [];
      this.form.category = [];
      this.form.address = "";
      this.similarSearchData = [];
      this.focusLocation = { lat: "", lng: "" };
      document.getElementById("map-auto-compleate").innerHTML = "";
      document.getElementById("map-auto-compleate").value = "";
      document.getElementById("emptyResult").classList.remove("bg-primary");
      document
        .getElementById("emptyResult")
        .classList.remove("list-group-item");
    },
    nameWithSelect({ name }) {
      return `${name}`;
    },

    getAddressData: function(addressData, placeResultData, id) {
      console.log(id);
      /*Get address using geocoder lat and lng */

      // var address = placeResultData.formatted_address.split(",");
      //this.form.address = address[0].trim(); //suburb
      // console.log(address);

      //Second Way two fetch suburb

      this.focusLocation = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      console.log(this.focusLocation);
      let fetchSuburb = this.getReverseGeocodingData(
        addressData.latitude,
        addressData.longitude,
        "map"
      );
      let suburbLocation = fetchSuburb.then((suburbvalue) => {
        this.form.address = suburbvalue.trim(); //suburb
        console.log(this.form.address);
      });
    },
    clearAutoCompleat() {
      let locationInput = document.getElementById("map-auto-compleate").value;
      if (
        locationInput == "" ||
        typeof locationInput == "undefined" ||
        locationInput == null
      ) {
        this.form.address = "";
      }
    },

    getReverseGeocodingData(lat, lng, type) {
      let latlng = new google.maps.LatLng(lat, lng);

      let geocoder = new google.maps.Geocoder();
      let promiseResult = new Promise(function(resolve, reject) {
        geocoder.geocode({ latLng: latlng }, function(results, status) {
          // called asynchronously
          if (status == google.maps.GeocoderStatus.OK) {
            if (type == "map") {
              console.log(results[0].formatted_address);
              let SuburbAddress = results[0].formatted_address.split(",");
              let Suburb = SuburbAddress[1]; //retrive the suburb
              resolve(Suburb);
            } else {
              console.log(results[0].formatted_address);
              let SuburbAddress = results[0].formatted_address.split(",");
              let Suburb = SuburbAddress[2]; //retrive the suburb
              resolve(Suburb);
            }
          } else {
            reject(status);
          }
        });
      });
      return promiseResult;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        // this.center = {
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // };
        this.center = {
          lat: -31.9665521,
          lng: 115.9344994,
        };
        var locateMe = this.getReverseGeocodingData(
          -31.9665521,
          115.9344994,
          "location"
        );

        let locateMeLocation = locateMe.then((suburbvalue) => {
          this.form.address = suburbvalue.trim(); //suburb
          console.log(this.form.address);
          this.SearchData();
        });
      });
    },
    locatorButtonPressed() {
      this.geolocate();

      this.zoom = 14;
      this.$refs.mapRef.$mapPromise.then((map) => {
        // map.panTo({lat: 39.9042, lng: 116.4074})
        this.zoom = 16;
        map.panTo(this.center);
      });

      setTimeout(() => {
        this.services.forEach((service) => {
          let center = new google.maps.LatLng(this.center);
          let targetLoc = new google.maps.LatLng({
            lat: Number(service.newlat),
            lng: Number(service.newlng),
          });

          let distance = google.maps.geometry.spherical.computeDistanceBetween(
            center,
            targetLoc
          );
          service["distance"] = Math.ceil(Number(distance) * 0.001);
          console.log(service);
        });
      }, 100);
    },
    onClickChild(value) {
      if (value) {
        this.selectedKey = value.id;
        this.infoWindowPos = value;
        this.infoWinOpen = true;
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.zoom = 12;
          map.panTo(value);
          this.infoContent = {
            name: value.title,
            phone: value.telephone,
            address: value.address,
            id: value.index,
          };
        });
      } else {
        this.infoWinOpen = false;
      }
    },
    convertRadius() {
      this.radius = Number(this.radius);
    },
    calculateReturn2() {
      var divs = document.querySelectorAll("[distance]"),
        i;
      console.log(divs);
      for (i = 0; i < divs.length; ++i) {
        const attrTotal = divs[i].getAttribute("distance");
        //  divs[i].className += " hideItem";
        if (this.radius == 0) {
          // divs[i].classList.remove("hideItem");
          this.zoom = 14;
          if (attrTotal > 2) {
            divs[i].className += " hideItem";
          }
        } else if (this.radius == 5) {
          this.zoom = 12;
          // divs[i].classList.remove("hideItem");
          if (attrTotal > 5) {
            divs[i].className += " hideItem";
          }
        } else if (this.radius == 10) {
          this.zoom = 10;
          // divs[i].classList.remove("hideItem");
          if (attrTotal > 10) {
            divs[i].className += " hideItem";
          }
        } else if (this.radius == 15) {
          // divs[i].classList.remove("hideItem");
          this.zoom = 9;
          if (attrTotal > 15) {
            // divs[i].className += " hideItem";
          }
        } else {
          this.zoom = 7;
          //divs[i].classList.remove("hideItem");
        }
        const hidden = document.querySelectorAll(".side-list-item.hideItem")
          .length;
        const seen = this.homeSearchData.length;
        this.totalItems = seen - hidden;
        console.log(this.totalItems);
      }

      this.convertRadius();
    },
    calculateReturn() {
      if (this.radius == 0) {
        this.zoom = 14;
      } else if (this.radius == 5) {
        this.zoom = 12;
      } else if (this.radius == 10) {
        this.zoom = 10;
      } else if (this.radius == 15) {
        this.zoom = 9;
      } else {
        this.zoom = 7;
      }
      this.convertRadius();
    },
    getMarkers(index) {
      if (this.selectedKey === index) return this.mapMarkerActive;
      return this.mapMarker;
    },
    toggleInfoWindow(service, idx) {
      (this.infoWindowPos = {
        lat: Number(service.newlat),
        lng: Number(service.newlng),
      }),
        (this.infoContent = {
          name: service.title,
          phone: service.telephone,
          address: service.address,
        });
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
  watch: {
    searchValue: function(newValue) {
      if (newValue.length > 3) {
        this.validName = true;
      } else if (newValue.length < 3) {
        this.validName = false;
      }
    },
    radius: function() {
      console.log("Radius call:" + this.radius);
      let percentage = (100 / 20) * this.radius;
      let sliderWidth = document.querySelector(".range-slider").clientWidth;
      let tooltipWidth = document.querySelector(".range-slider-tooltip")
        .clientWidth;
      let calc = sliderWidth - tooltipWidth - 5;
      let positionCalc = (percentage / 100) * calc;
      this.positionCalc = positionCalc;
    },
  },

  mounted() {
    // console.log(this.componentLoaded)
    if (this.componentLoaded === false) {
      // console.log('mounted');
    }
    // this.$refs.autocomplete.focus();
    this.$refs.address.clear();
  },

  created() {
    // console.log('created');
    const axios = require("axios");

    (this.componentLoaded = true),
      // axios.get("http://localhost:5000/api/service")
      axios
        .get("service/?token=005182f96b3e68d0acc30fbccfc633d5")

        .then((response) => {
          //console.log(response);
          this.servicesJson = response.data;
          console.log("step1");
        })
        .then((response) => {
          this.searchServices();
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        })
        .finally(() => {
          this.componentLoaded = false;
          //console.log(this.componentLoaded)
          // console.log(this.servicesJson)
        });
  },
};
</script>

<style>
#keyword {
  height: 52px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid #000;
  -webkit-box-shadow: inset 0 0 4px #ededed;
  box-shadow: inset 0 0 4px #ededed;
  width: 100%;
  padding: 8px 7px;
  margin-top: 10px;
  margin-left: 5px !important;
}
.multiselect__tags {
  border: 1px solid #1a1919 !important;
}
.multiselect__tag {
  background-color: #00a8a5 !important;
}
.multiselect__option--highlight {
  background: #00a8a5 !important;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight ::after {
  background: #00a8a5 !important;
}
.search-btn {
  color: #fff !important;
}
span.multiselect__placeholder {
  font-weight: bold !important;
}
.search-section {
  align-items: center;
}
.row.search-section > div {
  margin: 0px 5px 10px 5px;
}
.list-group-item:hover {
  box-shadow: 0 3px 8px 1px rgb(93 98 109 / 35%);
  color: #343b48;
}
.hideItem {
  display: none;
}

.showItem {
  display: block;
}
.range-slider-holder {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.range-slider-holder p {
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0;
  padding: 18px 18px 18px 0;
}
.range-slider-holder p:last-child {
  padding: 18px 0 18px 18px;
}
.range-slider-tooltip {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: 0;
  left: 100px;
  background: #e5e5e5;
  border-radius: 32px;
  color: black;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0 1rem;
  pointer-events: none;
  border: 1px solid black;
}

.range-slider {
  width: 100%;
}
</style>
