<template>
  <div id="app">
    <router-view :loading="loading" />
  </div>
</template>
<!--<template>
  <div id="homesearch">
    <router-view :loading="loading" />
  </div>
</template>-->

<script>
import json from "@/stores/ServiceStore.json";
export default {
  name: "App",
  components: {},
  data() {
    return {
      services: [],
      servicesJson: json.servicesJson,
      success: false,
      error: false,
      loading: false,
    };
  },
  async mounted() {
    const axios = require("axios");

    (this.loading = true),
      // axios.get("http://localhost:5000/api/service")
      axios
        .get("service/?token=005182f96b3e68d0acc30fbccfc633d5")
        .then((response) => {
          this.servicesJson = response.data;
          // console.log(response.data);
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          // console.log(this.loading)
          // console.log(this.servicesJson)
        });
  },
};
</script>
