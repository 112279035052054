<template>
  <div class="container-fluid">
    <div class="diagonal-box"></div>
    <div class="row">
      <div class="col-12">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-12 ">
              <div class="fixed-google-map mb-lg-5">
                <GmapMap ref="mapRef" 
                  :center="center" 
                  :zoom="zoom" 
                  style='width:100%;  height: 400px;'
                  :options="mapStyle"
                >
                  <gmap-info-window 
                    :options="infoOptions " 
                    :position="infoWindowPos" 
                    :opened="infoWinOpen" 
                    @closeclick="infoWinOpen=false"
                   
                  >
                  <div class="info-window">
                    <p class="mb-0"><b>{{infoContent.name}}</b></p>
                    <p class="mb-0">Phone: {{infoContent.phone}}</p>
                    <a :href="`https://maps.google.com/maps?daddr=${infoContent.address}`">Get Directions</a>
                  </div>
                  </gmap-info-window>

                  <GmapMarker
                    :key="index"
                    v-for="(m, index) in services"
                    :position="{lat:Number(m.newlat),lng: Number(m.newlng )}"
                    :clickable="true"
                    @click="toggleInfoWindow(m,index)"
                    :id ="m.id"
                     icon="https://wayouthservicesdirectory.org.au/wp-content/uploads/2021/07/ways-map-marker1.png"
                  />
                </GmapMap>
                <div class="search d-flex flex-row w-100">
                  <span class="material-icons" ref="okBtn" @click="locatorButtonPressed">
                    travel_explore
                  </span>
                  <div class="slidecontainer">
                    <input type="range" min="0" max="20" class="slider" v-model="radius" step="5" @change="calculateReturn" />
                    <p>within {{ radius }} kms</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12">

              <div class="store-list left">
                <div class="col-12  border-dotted">
                  <div class="row w-100">
                    <div class="col-lg-4 col-12">
                      <p class="mb-0"><span class="font-weight-bold mb-0">Advanced filter</span>{{services.length}}</p>
                    </div>
                    <div class="col-lg-8 col-12 d-flex flex-row justify-content-between align-items-center">
                      <p class="mb-0 d-flex flex-row justify-content-center align-items-center" @click="sortBy('title')">Alphabetical <span
                          class="material-icons">expand_more</span></p>
                      <p class="mb-0 d-flex flex-row justify-content-center align-items-center" ref="distBtn" @click="sortBy('distance')">Distance <span
                          class="material-icons">expand_more</span></p>
                      <p class="mb-0 d-flex flex-row justify-content-center align-items-center" @click="sortIntake('serviceIntake')">Intakes <span
                          class="material-icons">expand_more</span></p>
                    </div>
                  </div>
                </div>
                <div v-for="service in services" :key="service.id" class="side-list">
                  <Item :service="service" :distance="service.distance" @clicked="onClickChild" />
                </div>
              </div>
            </div>
          </div>
   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


import {gmapApi} from 'vue2-google-maps'
import LiveTracker from '@/components/LiveTracker.vue'
import Item from '@/components/Item.vue'
export default {
  components: {
     LiveTracker, Item
  },
  props: ['services'],
  computed: {
    gmapApi
  },
  name: 'AllServices',
  data () {
    return {
      isActive: true,
      center: {
        lat: -31.949003199248203,
        lng: 115.86049272274552
      },
      currentPlace: null,
      markers: [],
      place: null,
      radius: 5,
      lat: 0,
      lng: 0,
      range: 5,
      zoom: 12,
      distance: 0,
      infoContent: {},
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      placeholder: '',
      mapStyle: {
           zoomControl: false,
           mapTypeControl: false,
           scaleControl: false,
           streetViewControl: false,
           rotateControl: false,
           fullscreenControl: false,
           disableDefaultUI: true,
           styles: [{featureType: "water",elementType: "geometry",stylers: [{color: "#e9e9e9"},{lightness: 17 } ] }, { featureType: "landscape", elementType: "geometry", stylers: [ { color: "#f5f5f5" }, { lightness: 20 } ] }, { featureType: "road.highway", elementType: "geometry.fill", stylers: [ { color: "#ffffff" }, { lightness: 17 } ] }, { featureType: "road.highway", elementType: "geometry.stroke", stylers: [ { color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 } ] }, { featureType: "road.arterial", elementType: "geometry", stylers: [ { color: "#ffffff" }, { lightness: 18 } ] }, { featureType: "road.local", elementType: "geometry", stylers: [ { color: "#ffffff" }, { lightness: 16 } ] }, { featureType: "poi", elementType: "geometry", stylers: [ { color: "#f5f5f5" }, { lightness: 21 } ] }, { featureType: "poi.park", elementType: "geometry", stylers: [ { color: "#dedede" }, { lightness: 21 } ] }, { elementType: "labels.text.stroke", stylers: [ { visibility: "on" }, { color: "#ffffff" }, { lightness: 16 } ] }, { elementType: "labels.text.fill", stylers: [ { saturation: 36 }, { color: "#333333" }, { lightness: 40 } ] }, { elementType: "labels.icon", stylers: [ { visibility: "off" } ] }, { featureType: "transit", elementType: "geometry", stylers: [ { color: "#f2f2f2" }, { lightness: 19 } ] }, { featureType: "administrative", elementType: "geometry.fill", stylers: [ { color: "#fefefe" }, { lightness: 20 } ] }, { featureType: "administrative", elementType: "geometry.stroke", stylers: [ { color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 } ] } ]        
      }
     
    }
    },
    mounted () {
    setTimeout(() => {
   this.$refs.okBtn.click();
    }, 2000);
 setTimeout(() => {
   this.$refs.distBtn.click()
    }, 2500);
  },

      computed: {
 
      },
      methods: {
        onClickChild (value) {
      // console.log(value) // someValue
        this.$refs.mapRef.$mapPromise.then((map) => {
      // map.panTo({lat: 39.9042, lng: 116.4074})
      map.panTo(value)
    })
    },

         toggleInfoWindow ( service, idx ) {
          this.infoWindowPos = {lat:Number(service.newlat),lng: Number(service.newlng )},
          this.infoContent = {name: service.title,
                  phone: service.telephone,
                  address: service.address}
          if ( this.currentMidx === idx ) {
            this.infoWinOpen = !this.infoWinOpen
          }
          else {
            this.infoWinOpen = true
            this.currentMidx = idx
          }
        },

         

        calculateReturn () {
          if ( this.radius == 0 ) {
            this.zoom = 14
          }
          else if ( this.radius == 5 ) {
            this.zoom = 12
          }
          else if ( this.radius == 10 ) {
            this.zoom = 10
          }
          else if ( this.radius == 15 ) {
            this.zoom = 9
          }
          else {
            this.zoom = 7
          }
          this.convertRadius()
        },

         geolocate () {
          navigator.geolocation.getCurrentPosition( position => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          } );
        },

        locatorButtonPressed () {
          this.geolocate();
          this.zoom = 14
          // console.log("OK OK")
          setTimeout(() => {
          this.services.forEach( service => {
                      let center = new google.maps.LatLng( this.center )
                      let targetLoc = new google.maps.LatLng( {lat:Number(service.newlat),lng: Number(service.newlng )} )
                      let distance = google.maps.geometry.spherical.computeDistanceBetween( center, targetLoc )
                      service[ 'distance' ] = Math.ceil(Number(distance) * 0.001)
                      // console.log(service[ 'distance' ])
                    } )
          // console.log('shot my shot')
          this.sortBy(distance);
       }, 100);
        },

      sortBy ( prop ) {
          this.services.sort( ( a, b ) => a[ prop ] < b[ prop ] ? -1 : 1 )
        },
          sortIntake ( prop ) {
          this.services.sort( ( a, b ) => a[ prop ] > b[ prop ] ? -1 : 1 )
        },

        convertRadius () {
          this.radius = Number( this.radius ) 
        },

        filterMarkers () {
          this.services = this.services.filter( service => service.distance < this.radius )
        },

        sortMarkers () {
          this.services.sort( ( a, b ) => a.distance - b.distance )
        },
        updateLocations () {
          this.convertRadius()
          this.zoom = 7
        },

      },
      created() {
      // console.log(this.services)
     },
    }
</script>

<style>


@media only screen and (max-width: 992px) {
 .fixed-google-map{
  position:relative;
   width: 100%;

}
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-webkit-slider-thumb::before {
    content: 'test';
}
</style>
